import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight, MessageSquare, Code, Brain, Clock, Zap, Target, Menu, X } from 'lucide-react';
import { Typewriter } from 'react-simple-typewriter';
import logo from "./logo.png";

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const featuresRef = useRef(null);
  const howItWorksRef = useRef(null);
  const pricingRef = useRef(null);

  const scrollToSection = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' });
    setIsMenuOpen(false);
  };

  const ComingSoonButton = ({ className = "" }) => (
    <motion.button
      className={`bg-white text-black font-bold py-3 px-6 rounded-full transition-colors relative overflow-hidden ${className}`}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
    >
      <motion.span
        className="absolute inset-0 bg-blue-500 opacity-30"
        animate={{
          scale: [1, 1.5, 1],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      />
      <span className="relative z-10">Coming Soon</span>
    </motion.button>
  );

  return (
    <div className="bg-black text-white min-h-screen">
      <nav className="fixed top-0 left-0 right-0 bg-black bg-opacity-90 z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center py-6">
            <img src={logo} alt="AlgoMock Logo" className="h-14 w-auto" />
            <div className="hidden md:flex space-x-6">
              <button onClick={() => scrollToSection(featuresRef)} className="hover:text-gray-300 transition-colors">Features</button>
              <button onClick={() => scrollToSection(howItWorksRef)} className="hover:text-gray-300 transition-colors">How it Works</button>
              <button onClick={() => scrollToSection(pricingRef)} className="hover:text-gray-300 transition-colors">Pricing</button>
              <ComingSoonButton />
            </div>
            <button className="md:hidden" onClick={() => setIsMenuOpen(!isMenuOpen)}>
              {isMenuOpen ? <X /> : <Menu />}
            </button>
          </div>
        </div>
      </nav>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="fixed inset-0 bg-black z-40 pt-24"
          >
            <div className="flex flex-col items-center space-y-6 p-4">
              <button onClick={() => scrollToSection(featuresRef)} className="text-xl hover:text-gray-300 transition-colors">Features</button>
              <button onClick={() => scrollToSection(howItWorksRef)} className="text-xl hover:text-gray-300 transition-colors">How it Works</button>
              <button onClick={() => scrollToSection(pricingRef)} className="text-xl hover:text-gray-300 transition-colors">Pricing</button>
              <ComingSoonButton />
            </div>
          </motion.div>
        )}
      </AnimatePresence>

      <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <section className="min-h-screen pt-32 md:pt-40 flex items-center justify-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center max-w-4xl mx-auto"
          >
            <h1 className="text-5xl md:text-7xl font-bold mb-8">
              <span>
                <Typewriter
                  words={['Master Your Technical Interviews with AI']}
                  loop={1}
                  cursor
                  cursorStyle='|'
                  typeSpeed={100}
                  deleteSpeed={50}
                  delaySpeed={1000}
                />
              </span>
            </h1>
            <p className="text-xl text-gray-400 mb-12">
              Experience real-time, AI-powered mock interviews tailored for software engineering roles. Practice anytime, anywhere, and boost your confidence.
            </p>
            <ComingSoonButton className="mx-auto flex items-center" />
          </motion.div>
        </section>

        <section ref={featuresRef} className="py-20 md:py-40">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-16 text-center">Key Features</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
              {[
                { icon: <MessageSquare className="w-12 h-12 text-blue-500" />, title: "AI-Powered Conversations", description: "Engage in natural, context-aware dialogues that simulate real interview scenarios." },
                { icon: <Code className="w-12 h-12 text-green-500" />, title: "Live Coding Environment", description: "Write, run, and debug code in real-time, just like in actual technical interviews." },
                { icon: <Brain className="w-12 h-12 text-purple-500" />, title: "Adaptive Difficulty", description: "Experience questions that adjust based on your skill level and performance." },
                { icon: <Clock className="w-12 h-12 text-yellow-500" />, title: "Timed Sessions", description: "Practice under realistic time constraints to improve your efficiency." },
                { icon: <Zap className="w-12 h-12 text-red-500" />, title: "Instant Feedback", description: "Receive immediate, detailed feedback on your performance after each session." },
                { icon: <Target className="w-12 h-12 text-indigo-500" />, title: "Personalized Learning Path", description: "Get tailored interview questions based on your weaknesses and goals." }
              ].map((feature, index) => (
                <motion.div
                  key={index}
                  className="bg-gray-900 p-8 rounded-lg transform transition-all duration-300 hover:scale-105 hover:shadow-xl"
                  whileHover={{ y: -5 }}
                >
                  {feature.icon}
                  <h3 className="text-2xl font-semibold mt-6 mb-4">{feature.title}</h3>
                  <p className="text-gray-400">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </section>

        <section ref={howItWorksRef} className="py-40">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-16 text-center">How It Works</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
              {[
                { step: 1, title: "Create Your Profile", description: "Sign up and tell us about your experience, target roles, and interview goals." },
                { step: 2, title: "Choose Your Challenge", description: "Select from a variety of interview types, including algorithms, system design, and behavioral questions." },
                { step: 3, title: "Practice and Improve", description: "Engage in AI-powered interviews, receive feedback, and track your progress over time." }
              ].map((item, index) => (
                <motion.div key={index} className="text-center" whileHover={{ scale: 1.05 }}>
                  <div className="bg-blue-600 text-white w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6 text-2xl font-bold">{item.step}</div>
                  <h3 className="text-2xl font-semibold mb-4">{item.title}</h3>
                  <p className="text-gray-400">{item.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>
        </section>

        <section ref={pricingRef} className="py-40">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-16 text-center">Pricing Plans</h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-16">
              {[
                { title: "Basic", price: "$9.99", period: "month", features: ["5 AI interviews per month", "Basic question bank", "Performance tracking"] },
                { title: "Pro", price: "$19.99", period: "month", features: ["Unlimited AI interviews", "Advanced question bank", "Detailed performance analytics", "Priority support"], isPro: true },
                { title: "Team", price: "Custom", period: "", features: ["Everything in Pro", "Team management dashboard", "Customized interview scenarios", "Dedicated account manager"] }
              ].map((plan, index) => (
                <motion.div
                  key={index}
                  className={`bg-gray-900 p-8 rounded-lg text-center transform transition-all duration-300 hover:scale-105 ${plan.isPro ? 'border-2 border-blue-500' : ''}`}
                  whileHover={{ y: -10 }}
                >
                  {plan.isPro && <div className="bg-blue-500 text-white py-1 px-4 rounded-full inline-block mb-4">Most Popular</div>}
                  <h3 className="text-2xl font-semibold mb-4">{plan.title}</h3>
                  <div className="text-4xl font-bold mb-6">{plan.price} {plan.period && <span className="text-xl font-normal">/ {plan.period}</span>}</div>
                  <ul className="text-gray-400 mb-8 space-y-3">
                    {plan.features.map((feature, i) => (
                      <li key={i}>{feature}</li>
                    ))}
                  </ul>
                  <ComingSoonButton />
                </motion.div>
              ))}
            </div>
          </motion.div>
        </section>

        <section className="py-40">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <h2 className="text-4xl md:text-5xl font-bold mb-8">Ready to Ace Your Next Interview?</h2>
            <p className="text-xl text-gray-400 mb-12 max-w-3xl mx-auto">Join thousands of developers who have improved their interview skills with AlgoMock.</p>
            <ComingSoonButton />
          </motion.div>
        </section>
      </main>
      <footer className="bg-gray-900 text-gray-400 py-12">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <p className="text-center">&copy; 2024 AlgoMock. All rights reserved.</p>
        </div>
      </footer>

      <AnimatePresence>
        {isMenuOpen && (
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            className="fixed inset-0 bg-black z-40 pt-28"
          >
            <div className="flex flex-col items-center space-y-6 p-4">
              <button onClick={() => scrollToSection(featuresRef)} className="text-xl hover:text-gray-300 transition-colors">Features</button>
              <button onClick={() => scrollToSection(howItWorksRef)} className="text-xl hover:text-gray-300 transition-colors">How it Works</button>
              <button onClick={() => scrollToSection(pricingRef)} className="text-xl hover:text-gray-300 transition-colors">Pricing</button>
              <ComingSoonButton />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default App;